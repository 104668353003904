import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Company = (props) => {
  // const companys = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-companies">
      <SEO title="会社概要" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>会社概要</h1>
              <p>J-mindシンボルの送電鉄塔は、線と線の確たるつながりを持つことが人と人とをつなげ、よりよき明日をつなげること。</p>
              <p>そして、森と自然と共生することにより、日々、パワーを給電して行く事にあります。</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          <div className="col-12">
            <img className="img-fluid" src="/page-data/company/jmind-company-vision.png"/>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <dl className="row">
          <dt className="col-sm-3">商号</dt>
          <dd className="col-sm-9">株式会社 J-mind</dd>
          <dt className="col-sm-3">設立</dt>
          <dd className="col-sm-9">2015年7月27日</dd>
          <dt className="col-sm-3">所在地</dt>
          <dd className="col-sm-9">
            <p className="mb-0">〒154-0012</p>
            <p className="mb-0">東京都世田谷区駒沢5-18-4</p>
          </dd>
          <dt className="col-sm-3">役員</dt>
          <dd className="col-sm-9">代表取締役 上野淳</dd>
          <dt className="col-sm-3 text-truncate">事業内容</dt>
          <dd className="col-sm-9">
            <p className="mb-0">■ マイコンシステムのノイズ設計のアドバイスとコンサルティング</p>
            <p className="mb-0">■ IT農業、介護ヘルスケア関連のシステムコーディネート</p>
            <p className="mb-0">■ マイコン及び周辺回路の技術支援</p>
          </dd>
          <dt className="col-sm-3">取引銀行</dt>
          <dd className="col-sm-9">
            <p className="mb-0">城南信用金庫 深沢支店</p>
            <p className="mb-0">みずほ銀行 世田谷支店</p>
          </dd>
        </dl>
      </div>

      <div className="container pt-2 mb-4">
        <Call button />
      </div>

    </Layout>
  );
};

export const query = graphql`
  query CompanyQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/company/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
            linkedinurl
            email
          }
        }
      }
    }
  }
`;

export default Company;
